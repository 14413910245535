<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>{{ $t('marketing') }}</page-title>
          <p class="tw-mb-3 tw-leading-relaxed">
            If interested further, please contact us at
          </p>
          <a href="mailto:marketing@mangolola.com"
             class="tw-text-red tw-leading-relaxed">marketing@mangolola.com</a>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: i18n.t('marketing'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.marketing')
        }
      ]
    }
  },
}
</script>
